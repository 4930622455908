import gql from 'graphql-tag'
import roleFragment from './RoleFragment'
import userFragment from './../../../../auth/graphql/userFragment'

export const GET_PERMISSIONS_BY_TYPE_QUERY = gql`
  query GetPermissionsByType($type: String!) {
    permissions: getPermissionsByType(type: $type) {
      id
      description
      name
      status
      type
    }
  }
`

export const GET_ROLES_ROOT_QUERY = gql`
  query GetRolesRoot {
    roles: getRolesRoot {
      ...${roleFragment}
    }
  }
`

export const GET_ROLES_PARTNER_QUERY = gql`
  query GetRolesPartner {
    roles: getRolesPartner {
      ...${roleFragment}
    }
  }
`

export const GET_USERS_ROOT_QUERY = gql`
  query GetUsersEntity {
    users: getUsersEntity {
      ...${userFragment}
    }
  }
`