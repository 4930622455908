import gql from 'graphql-tag'
import userFragment from '../../../../auth/graphql/userFragment'

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($id: ID!, $userInput: UserInput) {
    updateUser(id: $id, userInput: $userInput) {
      ...${userFragment}
    }
  }
`
export const CREATE_USER_MUTATION = gql`
  mutation CreateUser($userInput: UserInput) {
    createUser(userInput: $userInput) {
      ...${userFragment}
    }
  }
`