import gql from 'graphql-tag'
import roleFragment from './RoleFragment'
// import userFragment from './../../../../auth/graphql/userFragment'
export const DELETE_ROLE_MUTATION = gql`
  mutation DeleteRole($id: ID!) {
    deleteRole(id: $id) {
      id
    }
  }
`
export const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`
export const CREATE_ROLE_MUTATION = gql`
  mutation CreateRole($roleInput: RoleInput) {
    createRole(roleInput: $roleInput) {
      ...${roleFragment}
    }
  }
`
export const UPDATE_ROLE_MUTATION = gql`
  mutation UpdateRole($id: ID!, $roleInput: RoleInput) {
    updateRole(id: $id, roleInput: $roleInput) {
      ...${roleFragment}
    }
  }
`