<template>
  <v-card class="text-center justify-center py-5">
    <v-responsive class="pt-4">
      <v-avatar
        size="100"
        color="grey lighten-4"
      >
        <img :src="
            user.photo
              ? `${apiUrl}/images/user/${user.photo}`
              : `/avatar.png`
          " />
      </v-avatar>
    </v-responsive>
    <v-card-text class="text-bottom">
      <v-subheader class="justify-center">{{ user.name }}</v-subheader>
      <div class="grey--text">{{ user.role.name }}</div>
    </v-card-text>
    <v-card-actions class="text-center justify-center">
      <v-btn
        icon
        @click="$emit('show', user)"
      >
        <v-icon>visibility</v-icon>
      </v-btn>
      <v-btn
        v-if="user.id != userId"
        icon
        @click="$emit('edit', user)"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        v-if="user.id != userId"
        icon
        @click="$emit('delete', user.id)"
      >
        <v-icon>delete</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { API_URL } from '@/api'
export default {
  name: 'UserCard',
  props: {
    user: Object
  },
  data: () => ({
    apiUrl: API_URL,
  }),
  computed: {
    userId () {
      return this.$root.$data.userId
    },
  }
}
</script>